import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { handleLogin, isLoggedIn } from '../services/auth'
import { Button, Form, Input, Message } from 'semantic-ui-react'

class Login extends Component {
  state = {
    username: '',
    password: '',
    error: '',
  }

  handleUpdate = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const loginError = await handleLogin(this.state)

    if (loginError) {
      this.setState({ error: loginError })
    }
  }

  render() {
    const { error } = this.state

    if (isLoggedIn()) {
      navigate(`/`)
    }

    return (
      <>
        <h1>Log in</h1>
        {error && <Message error header={error} content="Username hint: What day?" />}
        <Form
          onSubmit={event => {
            this.handleSubmit(event)
          }}
        >
          <Form.Field
            id="username"
            type="text"
            name="username"
            label="Username"
            control={Input}
            placeholder="What day?"
            onChange={this.handleUpdate}
            autoFocus
          />
          <Form.Field
            id="password"
            type="password"
            name="password"
            label="Password"
            control={Input}
            onChange={this.handleUpdate}
            autoFocus
          />

          <Form.Field type="submit" control={Button} content="Login" positive />
        </Form>
      </>
    )
  }
}

export default Login
